import { Options } from 'ky';

import { captchaDecorator } from '@shared/helpers/captcha';

import { Wallet } from '@tonconnect/ui-react';

import { deserialize, JsonApiObject } from '../helpers/jsonapi';
import Api, { ApiOptions } from './ApiProvider';
import {
  AdButlerEvent,
  ClaimRewardsResponse,
  ConnectedTonWallet,
  DeserializedReviewUser,
  DeserializedStorePageApp,
  DeserializedUser,
  DeserializedUserPublicProfile,
  FaceLivenessResult,
  FaceLivenessSession,
  LAUNCHPAD_NOTIFICATION_CATEGORY,
  LaunchpadNotificationCategory,
  LaunchpadUserNotification,
  MissingFieldsEntry,
  RequestSignResult,
  WalletType,
} from './types';
import { PaginationSearchParams } from './types/common';
import { HotOffer } from './types/hotOffers';
import { REVIEW_TYPE } from './types/review';

export const typeToUrl: { [key: string]: 'trusted-reviews' | 'reviews' | 'suggestions' } = {
  'trust-review': 'trusted-reviews',
  review: 'reviews',
  suggestion: 'suggestions',
};

export const getUserData = async (options?: Options) => {
  const rawUserData = await Api.get('v2/magicid/user/me', options).json<JsonApiObject>();

  return deserialize<DeserializedUser>(rawUserData);
};

export type GetUserLaunchpadNotificationSearchParams = {
  category?: LaunchpadNotificationCategory;
} & PaginationSearchParams;

export const DEFAULT_LIMIT_LAUNCHPAD_NOTIFICATION = 10;

export const getUserLaunchpadNotification = async (
  options?: ApiOptions<{ searchParams?: GetUserLaunchpadNotificationSearchParams }>,
) => {
  const optionsWithDefaults: typeof options = {
    ...options,
    searchParams: {
      ...options?.searchParams,
      limit: DEFAULT_LIMIT_LAUNCHPAD_NOTIFICATION,
    },
  };

  if (optionsWithDefaults.searchParams?.category === LAUNCHPAD_NOTIFICATION_CATEGORY.ALL) {
    delete optionsWithDefaults.searchParams?.category;
  }

  const data = await Api.get('launchpad/notifications', optionsWithDefaults).json<JsonApiObject>();

  return deserialize<LaunchpadUserNotification[]>(data);
};

export const sendProfileEmailVerificationCode = (
  options: ApiOptions<{ json: { email: string } }>,
) => Api.post('v2/magicid/user/email/send-code', options).json();

export const verifyProfileEmailVerificationCode = (
  options: ApiOptions<{ json: { email: string; code: number } }>,
) => Api.post('v2/magicid/user/email/verify-code', options).json();

export const updateUserData = (options: ApiOptions<{ json: { avatar: string } }>) =>
  Api.put('v2/magicid/user/avatar', options).json<{
    icon: string;
    id: number;
    name: string;
  }>();

export const addWallet = (
  options: ApiOptions<{
    json: Pick<RequestSignResult, 'address' | 'network'> & {
      wallet: WalletType;
    };
  }>,
) => Api.post('v2/magicid/user/wallets', options).json();

export const checkMagicIDForNecessaryData = captchaDecorator<
  ApiOptions<{
    searchParams: {
      name?: 1;
      email?: 1;
      avatar?: 1;
      displayedName?: 1;
      'wallets[]'?: 'SOLANA' | 'EVM';
      humanityScore?: 1;
      faceCheckPassed?: 1;
    };
  }>,
  MissingFieldsEntry
>(
  async (options) => Api.get('v2/magicid/user/params/check', options).json(),
  'checkMagicIDForNecessaryData',
);

export const getReferralUrl = captchaDecorator(
  async (
    options: ApiOptions<{
      json: { url: string };
    }>,
  ) => Api.post('v2/karma/sharing/create-link', options).json<{ sharingLink: string }>(),
  'getReferralUrl',
);

export const updateMagicIDInfo = (options: ApiOptions<{ json: { name: string } }>) =>
  Api.put('v2/magicid/user', options).json();

export const checkUserName = (options: ApiOptions<{ json: { name: string } }>) =>
  Api.post('v2/magicid/user/name/check', options).json();

export const updateUserAdditionalInfo = (
  options: ApiOptions<{
    json: { displayedName: string } | { description: string } | { cover: string | null };
  }>,
) => Api.put('v2/magicid/user/additional-info', options).json();

export const sendVerificationCode = (options: ApiOptions<{ json: { email: string } }>) =>
  Api.post('v2/magicid/user/email/send-code', options).json();

export const confirmVerificationCode = (
  options: ApiOptions<{ json: { email: string; code: number } }>,
) => Api.post('v2/magicid/user/email/verify-code', options).json();

export const deleteMagicIDEmail = () => Api.delete('v2/magicid/user/email').json();

export const claimSelfKarmaReward = captchaDecorator<
  ApiOptions<{
    json: {
      message?: string;
      signature?: string;
    };
  }>
>(
  async (options) => Api.put('v2/karma/rewards/claim', options).json<ClaimRewardsResponse>(),
  'claimSelfKarmaReward',
  true,
);

export const getSIWENonce = captchaDecorator(
  async (options?: ApiOptions) => Api.get('v2/magicid/nonce', options).json<string>(),
  'getSIWENonce',
  true,
);

export const refreshSelfHumanityScore = (options?: Options) =>
  Api.post('v2/magicid/user/gitcoin', options).json<{
    acceptable: boolean;
    score: number;
    nextUpdateDate: string;
  }>();

export const getUserProfile = async ({
  searchParams: { id, ...searchParams },
}: ApiOptions<{ searchParams: { id: string } }>) => {
  const data = await Api.get(`v2/store/user/${id}/profile`, { searchParams }).json<JsonApiObject>();
  return deserialize<DeserializedUserPublicProfile>(data);
};

export const getUserVotedApps = async ({
  searchParams: { id, ...searchParams },
}: ApiOptions<{ searchParams: { id: string; limit: number; offset?: number } }>) => {
  const data = await Api.get(`v2/store/user/${id}/profile/voted`, {
    searchParams,
  }).json<JsonApiObject>();
  return deserialize<DeserializedStorePageApp[]>(data);
};

export const getUserFollowedApps = async ({
  searchParams: { id, ...searchParams },
}: ApiOptions<{ searchParams: { id: string; limit: number; offset?: number } }>) => {
  const data = await Api.get(`v2/store/user/${id}/profile/followed`, {
    searchParams,
  }).json<JsonApiObject>();
  return deserialize<DeserializedStorePageApp[]>(data);
};

export const REVIEW_TYPE_FILTER = {
  ALL: 'all',
  ...REVIEW_TYPE,
} as const;

export type ReviewTypeFilterName = keyof typeof REVIEW_TYPE_FILTER;

export type ReviewTypeFilter = (typeof REVIEW_TYPE_FILTER)[ReviewTypeFilterName];

export const REVIEW_PERIOD = {
  ALL: 'all',
  LAST_30_DAYS: '30d',
  LAST_3_MONTHS: '3m',
  LAST_6_MONTHS: '6m',
  LAST_12_MONTHS: '12m',
} as const;

export type ReviewPeriodName = keyof typeof REVIEW_PERIOD;

export type ReviewPeriod = (typeof REVIEW_PERIOD)[ReviewPeriodName];

export type GetUserReviewsSearchParams = {
  limit?: number;
  offset?: number;
  period?: ReviewPeriod;
  type?: ReviewTypeFilter;
};

export const DEFAULT_GET_USER_REVIEWS_LIMIT = 20;

export const getUserReviews = async (
  userId: string,
  options?: ApiOptions<{
    searchParams: GetUserReviewsSearchParams;
  }>,
) => {
  const optionsWithDefaults = {
    ...options,
    searchParams: {
      offset: 0,
      limit: DEFAULT_GET_USER_REVIEWS_LIMIT,
      period: 'all',
      ...options?.searchParams,
    },
  };

  if (optionsWithDefaults.searchParams.type === 'all') {
    delete optionsWithDefaults.searchParams.type;
  }

  const data = await Api.get(
    `v2/store/user/${userId}/profile/reviews`,
    optionsWithDefaults,
  ).json<JsonApiObject>();

  return deserialize<DeserializedReviewUser[]>(data);
};

export const getUserReview = async ({
  searchParams: { id, type, ...searchParams },
}: ApiOptions<{
  searchParams: { id: string; type: 'suggestion' | 'review' | 'trust-review' };
}>) => {
  const data = await Api.get(`v2/store/application/${typeToUrl[type]}/${id}`, {
    searchParams,
  }).json<JsonApiObject>();
  return deserialize<DeserializedReviewUser>(data);
};

export const getFaceLivenessSession = (options?: Options) =>
  Api.post('v2/magicid/face-liveness/start', options).json<FaceLivenessSession>();

export const getFaceLivenessSessionResult = (
  options: ApiOptions<{ searchParams: { sessionId: string } }>,
) => Api.get('v2/magicid/face-liveness/results', options).json<FaceLivenessResult>();

export const DEFAULT_USER_CLAIMED_HOT_OFFERS_LIMIT = 20;

export const getUserClaimedHotOffers = async (
  userId: string,
  options?: ApiOptions<{
    searchParams: GetUserReviewsSearchParams;
  }>,
) => {
  const optionsWithDefaults = {
    ...options,
    searchParams: {
      offset: 0,
      limit: DEFAULT_GET_USER_REVIEWS_LIMIT,
      ...options?.searchParams,
    },
  };

  const data = await Api.get(
    `v2/store/user/${userId}/profile/hot-offers`,
    optionsWithDefaults,
  ).json<JsonApiObject>();

  return deserialize<HotOffer[]>(data);
};

export const connectTonWallet = (options: ApiOptions<{ json: Wallet }>) => {
  return Api.post('v2/magicid/user/wallet/connect/ton', options).json<ConnectedTonWallet>();
};

export const userEventViewableAd = (
  options: ApiOptions<{ json: { rawData: AdButlerEvent; eventType: 'impression' } }>,
) => {
  return Api.post('v2/integrations/adbutler/ad-event', options).json();
};

export const toggleAdsForUser = (enabled: boolean) => {
  return Api.put('v2/magicid/user/ad/toggle', { json: { enabled } });
};

export const loginViaTonWallet = (
  options: ApiOptions<{
    json: Wallet & { referredBy?: string; userCreationAllowed?: boolean };
  }>,
) => {
  return Api.post('v2/magicid/auth/login/ton', options).json<{
    // eslint-disable-next-line @typescript-eslint/ban-types
    message: 'Authorized' | (string & {});
    newUser: boolean;
    code?: boolean;
  }>();
};
