import { Address } from 'viem';

import { MembershipTierId } from '@shared/api';

export interface LaunchpadProjectListItem {
  type: string;
  id: string;
  attributes: ProjectAttributes;
  meta: ProjectMeta;
}

export interface LaunchpadProject {
  type: string;
  id: string;
  attributes: ProjectAttributes;
  salePool: LaunchpadSalePool[];
  meta: ProjectMeta;
}

interface ProjectMeta {
  claimingEndDate: string;
  claimingStartDate: string;
  endDate: string;
  goal: number;
  startDate: string;
  price: number;
  deposited: number;
  minAllocation: number;
  maxAllocation: number;
  participantCount: number;
}

export const LAUNCHPAD_SALE_POOL_WHITELIST_STRATEGY = {
  NONE: 'NONE',
  ONLY_ONE: 'ONLY_ONE',
  EVERY: 'EVERY',
} as const;

export type LaunchpadSalePoolWhitelistStrategy =
  (typeof LAUNCHPAD_SALE_POOL_WHITELIST_STRATEGY)[keyof typeof LAUNCHPAD_SALE_POOL_WHITELIST_STRATEGY];

export const LAUNCHPAD_SALE_POOL_WHITELIST_STRATEGY_LABEL: Record<
  LaunchpadSalePoolWhitelistStrategy,
  string
> = {
  [LAUNCHPAD_SALE_POOL_WHITELIST_STRATEGY.NONE]: 'None',
  [LAUNCHPAD_SALE_POOL_WHITELIST_STRATEGY.ONLY_ONE]: 'Only One',
  [LAUNCHPAD_SALE_POOL_WHITELIST_STRATEGY.EVERY]: 'Every',
} as const;

export interface WhitelistCreateInterface {
  criteriaIds: string[];
  criteriaCheckStrategy: LaunchpadSalePoolWhitelistStrategy;
  allocationSize: number;
  startDate: string; // ISO date string
  endDate: string; // ISO date string
}

export const WHITELIST_CRITERIA_TYPE = {
  PRIVATE: 'PRIVATE',
  MEMBERSHIP: 'MEMBERSHIP',
} as const;

export type WhitelistCriteriaType =
  (typeof WHITELIST_CRITERIA_TYPE)[keyof typeof WHITELIST_CRITERIA_TYPE];

export const WHITELIST_CRITERIA_TYPE_LABEL: Record<WhitelistCriteriaType, string> = {
  [WHITELIST_CRITERIA_TYPE.PRIVATE]: 'Private',
  [WHITELIST_CRITERIA_TYPE.MEMBERSHIP]: 'Membership',
} as const;

export interface WhitelistCriteria {
  id: string;
  type: 'whiteListJoiningCriteria';
  attributes: {
    type: WhitelistCriteriaType;
    title: string;
    description: string;
    settings?: {
      minTier?: MembershipTierId;
    };
  };
}

export interface WhitelistParticipant {
  id: string;
  type: 'whiteListParticipant';
  attributes: {
    wallet: Address;
  };
}

export interface WhitelistCriteriaCreateData {
  type: WhitelistCriteriaType;
  title: string;
  description: string;
  settings?: {
    minTier?: MembershipTierId | null;
  };
}

export const EVM_BLOCKCHAIN_NETWORK_TYPE = {
  ETHEREUM_MAINNET: 'ETHEREUM_MAINNET',
  BSC_MAINNET: 'BSC_MAINNET',
  ARBITRUM_MAINNET: 'ARBITRUM_MAINNET',
} as const;

export type EvmBlockchainNetworkTypeName = keyof typeof EVM_BLOCKCHAIN_NETWORK_TYPE;

export type EvmBlockchainNetworkType =
  (typeof EVM_BLOCKCHAIN_NETWORK_TYPE)[EvmBlockchainNetworkTypeName];

export const EVM_BLOCKCHAIN_NETWORK_TYPE_LABEL: Record<EvmBlockchainNetworkType, string> = {
  [EVM_BLOCKCHAIN_NETWORK_TYPE.ETHEREUM_MAINNET]: 'Ethereum Mainnet',
  [EVM_BLOCKCHAIN_NETWORK_TYPE.BSC_MAINNET]: 'BSC Mainnet',
  [EVM_BLOCKCHAIN_NETWORK_TYPE.ARBITRUM_MAINNET]: 'Arbitrum Mainnet',
} as const;

// BLOCKCHAIN_ID

export const EVM_BLOCKCHAIN_NETWORK_ID = {
  [EVM_BLOCKCHAIN_NETWORK_TYPE.ETHEREUM_MAINNET]: 1,
  [EVM_BLOCKCHAIN_NETWORK_TYPE.BSC_MAINNET]: 56,
  [EVM_BLOCKCHAIN_NETWORK_TYPE.ARBITRUM_MAINNET]: 42161,
} as const;

export type EvmBlockchainNetworkIdName = keyof typeof EVM_BLOCKCHAIN_NETWORK_ID;

export type EvmBlockchainNetworkId = (typeof EVM_BLOCKCHAIN_NETWORK_ID)[EvmBlockchainNetworkIdName];

export const EVM_BLOCKCHAIN_NETWORK_ID_LABEL: Record<EvmBlockchainNetworkId, string> = {
  [EVM_BLOCKCHAIN_NETWORK_ID[EVM_BLOCKCHAIN_NETWORK_TYPE.ETHEREUM_MAINNET]]:
    EVM_BLOCKCHAIN_NETWORK_TYPE_LABEL[EVM_BLOCKCHAIN_NETWORK_TYPE.ETHEREUM_MAINNET],
  [EVM_BLOCKCHAIN_NETWORK_ID[EVM_BLOCKCHAIN_NETWORK_TYPE.BSC_MAINNET]]:
    EVM_BLOCKCHAIN_NETWORK_TYPE_LABEL[EVM_BLOCKCHAIN_NETWORK_TYPE.BSC_MAINNET],
  [EVM_BLOCKCHAIN_NETWORK_ID[EVM_BLOCKCHAIN_NETWORK_TYPE.ARBITRUM_MAINNET]]:
    EVM_BLOCKCHAIN_NETWORK_TYPE_LABEL[EVM_BLOCKCHAIN_NETWORK_TYPE.ARBITRUM_MAINNET],
} as const;

// CLAIMING_BLOCKCHAIN_TYPE

export const LAUNCHPAD_PROJECT_CLAIMING_BLOCKCHAIN_TYPE = {
  EVM: 'EVM',
  SOLANA: 'SOLANA',
  TON: 'TON',
} as const;

export type LaunchpadProjectClaimingBlockchainTypeName =
  keyof typeof LAUNCHPAD_PROJECT_CLAIMING_BLOCKCHAIN_TYPE;

export type LaunchpadProjectClaimingBlockchainType =
  (typeof LAUNCHPAD_PROJECT_CLAIMING_BLOCKCHAIN_TYPE)[LaunchpadProjectClaimingBlockchainTypeName];

export const LAUNCHPAD_PROJECT_CLAIMING_BLOCKCHAIN_TYPE_LABEL: Record<
  LaunchpadProjectClaimingBlockchainType,
  string
> = {
  [LAUNCHPAD_PROJECT_CLAIMING_BLOCKCHAIN_TYPE.EVM]: 'EVM (Ethereum Virtual Machine)',
  [LAUNCHPAD_PROJECT_CLAIMING_BLOCKCHAIN_TYPE.SOLANA]: 'Solana',
  [LAUNCHPAD_PROJECT_CLAIMING_BLOCKCHAIN_TYPE.TON]: 'TON (Telegram Open Network)',
} as const;

// CLAIMING_TYPE

export const LAUNCHPAD_PROJECT_CLAIMING_TYPE = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
} as const;

export type LaunchpadProjectClaimingTypeName = keyof typeof LAUNCHPAD_PROJECT_CLAIMING_TYPE;

export type LaunchpadProjectClaimingType =
  (typeof LAUNCHPAD_PROJECT_CLAIMING_TYPE)[LaunchpadProjectClaimingTypeName];

export const LAUNCHPAD_PROJECT_CLAIMING_TYPE_LABEL: Record<LaunchpadProjectClaimingType, string> = {
  [LAUNCHPAD_PROJECT_CLAIMING_TYPE.INTERNAL]: 'Internal',
  [LAUNCHPAD_PROJECT_CLAIMING_TYPE.EXTERNAL]: 'External',
} as const;

interface ProjectAttributes {
  archived: boolean;
  bannerSmallUrl: string;
  bannerUrl: string;
  bannerUrls: string[];
  claimingBlockchain: LaunchpadProjectClaimingBlockchainType;
  claimingBlockchainId: EvmBlockchainNetworkId;
  claimingEndDate: string;
  claimingInfo: string;
  claimingStartDate: string;
  earlyAccess: boolean;
  claimingType: LaunchpadProjectClaimingType;
  description: string;
  endDate: string;
  index: boolean;
  isComingSoon: boolean;
  isHidden: boolean;
  isSolana: boolean;
  links: LaunchpadProjectLink[];
  logoUrl: string;
  meta: string;
  name: string;
  slug: string;
  startDate: string;
  tags: string[];
  ticker: string;
  title: string;
  tokenSupply: number;
  refundDate?: string;
  refund?: boolean;
}

export const LAUNCHPAD_PROJECT_LINK_TYPE = {
  DOCUMENT: 'DOCUMENT',
  WEBSITE: 'WEBSITE',
  TELEGRAM: 'TELEGRAM',
  MEDIUM: 'MEDIUM',
  TWITTER: 'TWITTER',
  YOUTUBE: 'YOUTUBE',
  DISCORD: 'DISCORD',
} as const;

export type LaunchpadProjectLinkType =
  (typeof LAUNCHPAD_PROJECT_LINK_TYPE)[keyof typeof LAUNCHPAD_PROJECT_LINK_TYPE];

export const LAUNCHPAD_PROJECT_LINK_TYPE_LABEL: Record<LaunchpadProjectLinkType, string> = {
  [LAUNCHPAD_PROJECT_LINK_TYPE.DOCUMENT]: 'Document',
  [LAUNCHPAD_PROJECT_LINK_TYPE.WEBSITE]: 'Website',
  [LAUNCHPAD_PROJECT_LINK_TYPE.TELEGRAM]: 'Telegram',
  [LAUNCHPAD_PROJECT_LINK_TYPE.MEDIUM]: 'Medium',
  [LAUNCHPAD_PROJECT_LINK_TYPE.TWITTER]: 'Twitter',
  [LAUNCHPAD_PROJECT_LINK_TYPE.YOUTUBE]: 'Youtube',
  [LAUNCHPAD_PROJECT_LINK_TYPE.DISCORD]: 'Discord',
} as const;

interface LaunchpadProjectLink {
  type: LaunchpadProjectLinkType;
  link: string;
  title: string;
}

export const LAUNCHPAD_NOTIFICATION_TYPE = {
  REFUND_DEPOSIT_TOKENS: 'REFUND_DEPOSIT_TOKENS',
  REFUND_SQRP: 'REFUND_SQRP',
  BUY_ALLOCATION_SQRP_GATED: 'BUY_ALLOCATION_SQRP_GATED',
  BUY_ALLOCATION_FCFS: 'BUY_ALLOCATION_FCFS',
  BUY_ALLOCATION_PRO_RATA: 'BUY_ALLOCATION_PRO_RATA',
  BUY_ALLOCATION_PRO_RATA_SQRP_GATED: 'BUY_ALLOCATION_PRO_RATA_SQRP_GATED',
  DEPOSIT_PRO_RATA: 'DEPOSIT_PRO_RATA',
  DEPOSIT_PRO_RATA_SQRP_GATED: 'DEPOSIT_PRO_RATA_SQRP_GATED',
  DEPOSIT_FCFS: 'DEPOSIT_FCFS',
  DEPOSIT_SQRP_GATED: 'DEPOSIT_SQRP_GATED',
  KYC_COMPLETED: 'KYC_COMPLETED',
  SQRP_REFUNDED: 'SQRP_REFUNDED',
  CLAIMING_STARTED: 'CLAIMING_STARTED',
  REGISTRATION_COMPLETED: 'REGISTRATION_COMPLETED',
  WHITELISTED: 'WHITELISTED',
  CLAIMING_FINISHED: 'CLAIMING_FINISHED',
};

export type LaunchpadNotificationTypeName = keyof typeof LAUNCHPAD_NOTIFICATION_TYPE;

export type LaunchpadNotificationType =
  (typeof LAUNCHPAD_NOTIFICATION_TYPE)[LaunchpadNotificationTypeName];

export const LAUNCHPAD_SALE_POOL_STATUS = {
  ANNOUNCED: 'ANNOUNCED',
  SALE_STARTED: 'SALE_STARTED',
  SALE_FINISHED: 'SALE_FINISHED',
  CLAIMING_ANNOUNCED: 'CLAIMING_ANNOUNCED',
  CLAIMING_STARTED: 'CLAIMING_STARTED',
  CLAIMING_FINISHED: 'CLAIMING_FINISHED',
} as const;

export type LaunchpadSalePoolStatus =
  (typeof LAUNCHPAD_SALE_POOL_STATUS)[keyof typeof LAUNCHPAD_SALE_POOL_STATUS];

export const LAUNCHPAD_SALE_POOL_STATUS_LABEL: Record<LaunchpadSalePoolStatus, string> = {
  [LAUNCHPAD_SALE_POOL_STATUS.ANNOUNCED]: 'Announced',
  [LAUNCHPAD_SALE_POOL_STATUS.SALE_STARTED]: 'Sale started',
  [LAUNCHPAD_SALE_POOL_STATUS.SALE_FINISHED]: 'Sale finished',
  [LAUNCHPAD_SALE_POOL_STATUS.CLAIMING_ANNOUNCED]: 'Claiming announced',
  [LAUNCHPAD_SALE_POOL_STATUS.CLAIMING_STARTED]: 'Claiming started',
  [LAUNCHPAD_SALE_POOL_STATUS.CLAIMING_FINISHED]: 'Claiming finished',
};

export const LAUNCHPAD_SALE_POOL_TYPE = {
  FCFS: 'FCFS',
  SQRP_GATED: 'SQRP_GATED',
  PRO_RATA: 'PRO_RATA',
  PRO_RATA_SQRP_GATED: 'PRO_RATA_SQRP_GATED',
} as const;

export type LaunchpadSalePoolType =
  (typeof LAUNCHPAD_SALE_POOL_TYPE)[keyof typeof LAUNCHPAD_SALE_POOL_TYPE];

export const LAUNCHPAD_SALE_POOL_TYPE_SLUG = {
  [LAUNCHPAD_SALE_POOL_TYPE.FCFS]: 'fcfs',
  [LAUNCHPAD_SALE_POOL_TYPE.SQRP_GATED]: 'sqrp-gated',
  [LAUNCHPAD_SALE_POOL_TYPE.PRO_RATA]: 'pro-rata',
  [LAUNCHPAD_SALE_POOL_TYPE.PRO_RATA_SQRP_GATED]: 'pro-rata-sqrp-gated',
} as const;

export type LaunchpadSalePoolTypeSlugValue =
  (typeof LAUNCHPAD_SALE_POOL_TYPE_SLUG)[LaunchpadSalePoolType];

export const LAUNCHPAD_SALE_POOL_TYPE_LABEL = {
  [LAUNCHPAD_SALE_POOL_TYPE.FCFS]: 'FCFS',
  [LAUNCHPAD_SALE_POOL_TYPE.SQRP_GATED]: 'SQRp Gated',
  [LAUNCHPAD_SALE_POOL_TYPE.PRO_RATA]: 'Pro Rata',
  [LAUNCHPAD_SALE_POOL_TYPE.PRO_RATA_SQRP_GATED]: 'Pro Rata SQRp Gated',
} as const;

export interface Whitelist {
  id: string;
  type: 'whiteList';
  attributes: {
    allocationSize: number;
    criteriaCheckStrategy: LaunchpadSalePoolWhitelistStrategy;
    endDate: string; // ISO date string;
    startDate: string; // ISO date string;
  };
}

export const LAUNCHPAD_SALE_POOL_DEPOSIT_TOKEN = {
  SQR: 'SQR',
  USDT: 'USDT',
} as const;

export type LaunchpadSalePoolDepositToken =
  (typeof LAUNCHPAD_SALE_POOL_DEPOSIT_TOKEN)[keyof typeof LAUNCHPAD_SALE_POOL_DEPOSIT_TOKEN];

export const LAUNCHPAD_ORDER_BY_TYPE = {
  ASC: 'ASC',
  DESC: 'DESC',
} as const;

export type LaunchpadOrderByType =
  (typeof LAUNCHPAD_ORDER_BY_TYPE)[keyof typeof LAUNCHPAD_ORDER_BY_TYPE];

export const LAUNCHPAD_PRIVATE_SALE_POOLS_ORDER_BY = {
  PROJECT_NAME: 'projectName',
  NAME: 'name',
  DEPOSIT_TOKEN_TICKER: 'depositTokenTicker',
  CLAIMING_START_DATE: 'claimingStartDate',
  FULL_REFUND_PROCESSING_FEE: 'fullRefundProcessingFee',
  FULL_REFUND_PROCESSING_START_DATE: 'fullRefundProcessingStartDate',
  FULL_REFUND_PROCESSING_END_DATE: 'fullRefundProcessingEndDate',
} as const;

export type LaunchpadPrivateSalePoolsOrderBy =
  (typeof LAUNCHPAD_PRIVATE_SALE_POOLS_ORDER_BY)[keyof typeof LAUNCHPAD_PRIVATE_SALE_POOLS_ORDER_BY];

export const LAUNCHPAD_PRIVATE_REFUNDS_ORDER_BY = {
  ACCEPTED_AMOUNT: 'acceptedAmount',
  FULL_REFUND_AMOUNT: 'fullRefundAmount',
  FULL_REFUND_REQUESTED_AT: 'fullRefundRequestedAt',
} as const;

export type LaunchpadPrivateRefundsOrderBy =
  (typeof LAUNCHPAD_PRIVATE_REFUNDS_ORDER_BY)[keyof typeof LAUNCHPAD_PRIVATE_REFUNDS_ORDER_BY];

export interface LaunchpadSalePool {
  id: string;
  type: 'salePool';
  meta?: {
    whiteListContainsUser?: boolean;
    whiteListExists?: boolean;
    isWhitelistParticipant?: boolean;
  };
  attributes: {
    name: string;
    description: string;
    claimContractAddress: Address;
    claimingEndDate: string; // ISO date string
    claimingStartDate: string; // ISO date string
    depositContractAddress: Address;
    depositTokenPrice: number;
    depositTokenTicker: LaunchpadSalePoolDepositToken;
    endDate: string; // ISO date string
    goal: number;
    maxAllocation: number;
    minAllocation: number;
    paymentsReceived: number;
    participantCount: number;
    price: number;
    externalLink?: string | null;
    startDate: string; // ISO date string
    status: LaunchpadSalePoolStatus;
    type: LaunchpadSalePoolType;
    turboChargeAvailable: boolean;
    fullRefundAvailable: boolean;
    fullRefundProcessingEndDate: string; // ISO date string
    fullRefundProcessingFee: number;
    fullRefundProcessingStartDate: string; // ISO date string
    meta?: {
      whiteListContainsUser?: boolean;
      whiteListExists?: boolean;
      isWhitelistParticipant?: boolean;
    };
    announcement: string | null;
    refund?: boolean;
    refundDate?: string | null;
  };
  whiteList?: Whitelist;
  project: LaunchpadProject;
}

export interface FcfsTransaction {
  type: string;
  id: string;
  attributes: FcfsTransactionAttributes;
  user: User;
}

interface FcfsTransactionAttributes {
  amount: number;
  signedDepositParams: SignedDepositParams;
}

interface SignedDepositParams {
  signature: string;
  amountInWei?: string;
  baseAmountInWei?: string;
  boostExchangeRateInWei?: string;
  nonce: number;
  timestampNow: number;
  timestampLimit: number;
}

interface User {
  type: 'user';
  id: string;
  attributes: UserAttributes;
}

interface UserAttributes {
  address: string;
  fraudster: boolean;
}

export interface LaunchpadPrivateAllocation {
  type: 'allocation';
  id: string;
  attributes: {
    acceptedAmount: number | null;
    amount: string | null;
    amountBought: string | null;
    depositTokenTicker: string | null;
    fullRefundAmount: number | null;
    fullRefundRequested: boolean;
    fullRefundTransactionHash: string | null;
    fullRefundRequestedAt: string | null; // ISO date string
    sqrpAmount: number | null;
  };
  salePool: LaunchpadSalePool & {
    attributes: {
      claimingStartDate: string; // ISO date string
      fullRefundAvailable: boolean;
      fullRefundProcessingEndDate: string; // ISO date string
      fullRefundProcessingFee: number;
      fullRefundProcessingStartDate: string; // ISO date string
      name: string;
      type: string;
    } | null;
  };
  project: LaunchpadProjectListItem;
  user: User;
}

interface UserAttributes {
  address: string;
  sqrpBalance: number;
}

export const LAUNCHPAD_NOTIFICATION_CATEGORY = {
  ALL: 'ALL',
  PROJECT_UPDATES: 'PROJECT_UPDATES',
  TRANSACTIONS: 'TRANSACTIONS',
  ACCOUNT: 'ACCOUNT',
} as const;

export type LaunchpadNotificationCategoryName = keyof typeof LAUNCHPAD_NOTIFICATION_CATEGORY;

export type LaunchpadNotificationCategory =
  (typeof LAUNCHPAD_NOTIFICATION_CATEGORY)[LaunchpadNotificationCategoryName];

export interface LaunchpadUserNotification {
  id: string;
  type: string;
  attributes: {
    payload?: {
      projectId?: string;
      projectName?: string;
      amountUSDT?: string;
      amountSQRP?: string;
    };
    category: LaunchpadNotificationCategory;
    createdAt: string;
    type?: LaunchpadNotificationType;
  };
}

export const LAUNCHPAD_PROJECT_STATUS = {
  COMING: 'coming',
  ACTIVE: 'active',
  PASSED: 'passed',
} as const;

export type LaunchpadProjectStatusName = keyof typeof LAUNCHPAD_PROJECT_STATUS;

export type LaunchpadProjectStatus = (typeof LAUNCHPAD_PROJECT_STATUS)[LaunchpadProjectStatusName];

export interface DeserializedSelfAllocationPool {
  id: string;
  name: string;
  claimContractAddress: Address;
  claimingEndDate: string; // ISO date string
  claimingStartDate: string; // ISO date string
  depositContractAddress: Address;
  endDate: string; // ISO date string
  goal: number;
  maxAllocation: number;
  minAllocation: number;
  paymentsReceived: number;
  participantCount: number;
  fullRefundAvailable: boolean;
  fullRefundProcessingEndDate: string; // ISO date string
  fullRefundProcessingFee: number;
  fullRefundProcessingStartDate: string; // ISO date string
  price: number;
  startDate: string; // ISO date string
  status: LaunchpadSalePoolStatus;
  type: LaunchpadSalePoolType;
  project?: any;
}

export interface DeserializedSelfAllocation {
  id: string;
  // available allocations
  amount: number;
  amountBought: number;
  // included in sqrp gated pools
  sqrpAmount?: number;
  salePool: DeserializedSelfAllocationPool | null;
  refundAmount?: number | null;
  acceptedAmount?: number | null;
  fullRefundTransactionHash?: string;
}

export interface DeserializedSelfAllocations {
  id: string;
  address: string;
  sqrBalance: number | null;
  fcfsAllocation: DeserializedSelfAllocation[];
  sqrpGatedAllocation: DeserializedSelfAllocation[];
  proRataAllocation: DeserializedSelfAllocation[];
  proRataSqrpGatedAllocation: DeserializedSelfAllocation[];
}
